.UserFilter .FilterField {
  width: 130px;
  margin-right: 20px;
}

.UserFilter .FilterValue {
  width: 245px;
  margin-right: 20px;
}

.UserFilter .FilterButton, .UserFilter .ClearButton {
  width: 70px;
  margin-right: 20px;
}

