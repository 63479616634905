.SearchPage button {
  margin-right: 5px;
}

.SearchPage .FileLink {
  font-size: 13px;
}

.Refinemer {
  margin-top: 5px;
}

.Refinemer .Form {
  padding: 5px;
}

.Refinemer .Form .MuiRadio-root, .Refinemer .Form .MuiCheckbox-root {
  padding: 5px;
}

.SearchPage .SearchResultCategoryHolder {
  padding: 5px 20px;
}