.AppFooter {
    font-size: 12px;
    font-weight: 400;
    color: var(--dark-grey);
    height: 115px;
    background-color: var(--light-grey);
    padding: 20px;
    box-sizing: border-box;
}

.AppFooter .MuiGrid-item:not(:last-child) {
    margin-bottom: 5px;
}

.QuanBig {
    font-size: 14px;
    font-weight: bold;
    color: var(--grey);
}

.AppFooter a {
    font-weight: bold;
}