.transparentDropzone{
    background-color: rgba(0,0,0,0.7) !important;;
    min-height: unset !important;
    height: inherit !important;    
    border-radius: 8px;
    border: 1px solid #b1b5c1;
}

.imageEditorContainer
{
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    height: inherit;
    width: inherit;
    
}

.transparentDropzone svg{
    color: var( --white-color);
}

.transparentDropzone p{
    color: var( --white-color);
    font-size: 20px;;
    font-weight: 600;
}

.folderImage {
    height: 100%;
}