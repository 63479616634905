.SupportSection{
    border-bottom: 1px solid rgba(227, 227, 227, 1);;
    font-size: 14px;
    padding-right: 15px;
    justify-content: space-between;
}

.SupportSectionText {
    padding:15px;
} 

.SupportSection a{
    font-weight: bold;
    margin-right: 20px;
}

.SupportSectionText span{
    margin-right: 10px;
}

#searchBarHolder {
    padding-top: 12px;
}