.LeftMenuList.MuiList-root .MuiListItem-root:hover{
    background-color: var(--light-bluegrey);
}

.LeftMenuList.MuiList-root .MuiListItem-root{
    cursor: pointer;
}

.SelectedLeftMenuItem.MuiListItem-root{
    color: var(--highlighted-text-color);
    font-weight: bold;
}

.SelectedLeftMenuItem.MuiListItem-root .MuiTypography-root {
    font-weight: bold;
}