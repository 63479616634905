.DefaultLayoutLogoImage {	
	width: 123px;
    height: 21px;
}

.DefaultLayoutHeader {
    margin-top: 32px;
    margin-bottom: 32px;    

    display: flex;
    justify-content: space-between;
}

.DefaultLayoutHeaderPrompt {
    text-align: right;
}

.DefaultLayoutFooter {
    width: 100%;
    text-align: center;
    margin-bottom: 32px;
}

.DefaultLayoutContainer {
    height: 100vh;
    margin-left: 64px;
    margin-right: 64px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
}

.DefaultLayoutContent {
    flex: 1;
}

.DefaultLayoutMainSection {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 100vh;
    background-image: url('src/assets/images/quanterix-hero.jpg');
    background-position: center;
    background-color: #e3e3e3;
    color: white;
}