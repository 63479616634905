.folderImage img{    
    max-width: 100%;
    max-height: 100%;
}

.folderContent
{
    font-weight: 400;
    font-style: normal;
    font-size: 16px;    
}

.folderHeader
{
    font-weight: 700;
    font-style: normal;
    text-align: left;
    font-size:32px;   
}

.headerMinHeight{
    min-height: 80px;
}

.textMinHeight
{
    min-height: 100px;
}

.dropzoneHeight  p{
    margin: 0.9rem 0 0 0;   
    color: var(--primary-color);
    font-size: 13px;
    font-weight: 400;
    line-height: 1.334;
    letter-spacing: 0em; 
}
.dropzoneHeight{
    background-color: transparent !important;
    border-radius: 8px;
    border-width: 1px !important;
}
.addFolderBox{
    border-radius: 10px;
    border: 1px solid #b1b5c1 !important;
}

.folderNameBox
{  
    border-radius: 8px;
    border: 1px solid #b1b5c1;
}

.folderHeader svg{
    color: var(--primary-color);
}

.folderContent svg{
    color: var(--primary-color);
}
/*
text editor styles
*/
#mui-rte-toolbar
{
    background-color:  #e8e6e6;
    border-bottom-color: #b1b5c1;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

#mui-rte-root{
    border-color: #b1b5c1;
    border-radius: 8px;    
}

#mui-rte-root div[data-contents=true]{
    margin-left: 7px;
    margin-right: 7px;
}

.noResultsFoundMessage {
    font-size: 14px !important;
}
