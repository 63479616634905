.MuiButton-contained.QuanButton {
    background-color: var(--highlighted-text-color);
}

.MuiButton-contained.QuanButton>.MuiButton-label {
    color: (--white-color);
}

.QuanButton.MuiButton-root {
    width: 115px;
    height: 35px;
    margin: 5px;
}

.QuanButton.MenuButton.MuiButton-root {
    width: auto;
}

.QuanButton.MuiButton-text {
    width: 140px;
}

.QuanButton.MuiButton-root>.MuiButton-label {
    text-transform: none;
    font-size: 12px;
}

.QuanButton.VsSelected.MuiButton-root>.MuiButton-label {
    font-size: 14px;
}

.QuanBadge.MuiBadge-root span.MuiBadge-colorSecondary {
    background-color: var(--primary-color);
    min-width: 10px;
    left: -9px;
    bottom: 12px;
    height: 15px;
    font-size: 10px;
    width: 15px;
}

.sortDown {
    margin-top: -2px;
    margin-left: 4px;
}

.MuiMenuItem-root.EditPagesMenuItem {
    font-size: 11px;
}

.EditableMenu .MuiCheckbox-root {
    color: var(--primary-color) !important;
    cursor: pointer;
}

.EditableMenu .MuiCheckbox-root.Mui-checked {
    color: var(--primary-color) !important;
}

.EditableMenu.MuiListItem-root {
    padding-top: 0;
    padding-bottom: 0;
    cursor: pointer;
}

.EditableMenu.MuiListItem-root .MuiListItemText-root>span.MuiTypography-root {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 14px
}

.EditableMenu.Selected.MuiListItem-root .MuiListItemText-root>span.MuiTypography-root {
    color: var(--primary-color);
    font-weight: bold;
}

.NestedList.MuiCollapse-container {
    margin-left: 30px
}

.AddPageLink span.MuiTypography-root {
    font-size: 12px!important;
    margin-left: 10px;
    color: var(--primary-color);
    cursor: pointer;
}

.ExpColl.MuiSvgIcon-root {
    color:black;
    vertical-align: middle;
    font-size: 1.1rem;
}

.MoreVert.MuiSvgIcon-root {
    color: grey;
}

.MenuVsShadow div.MuiMenu-paper {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)!important;
}