.FolderViewerSearchBar .SearchSwitch {
  margin-right: 0;
}

.FolderViewerSearchBar button {
  margin-right: 5px;
  width: 78px;
}

.FolderViewerSearchBar button span {
  margin-right: 0;
}

.FolderViewerSearchBar .UserEdit .MuiInputBase-root {
  width: 220px;
}

.FolderViewerSearchBar .SearchTipsIcon {
  cursor: pointer;
  margin-top: 3px;
}

.FolderViewerSearchBar .SearchTipsIcon:hover {
  opacity: 0.8;
}

.SearchTips {
  padding: 10px;
  font-size: 14px !important;
}