.App-header {
	height: 55px;
	display: flex;
	justify-content: space-between;
	box-shadow: 0 2px 4px 0 rgba(58, 75, 56, 0.2), 0 3px 10px 0 rgba(58, 75, 56, 0.19);
	background-color: var(--highlighted-text-color);
	color: var(--white-color);
	z-index: 100;
}

.Header-logo {
	margin-top: 17px;
	margin-left: 20px;
	width: 123px;
	height: 21px;
}

.UserMenu {
	margin-right: 15px;
	cursor: pointer;
	margin-left: 15px;
}

.MenuWrapper {
	justify-content: flex-end;
	align-items: center;
	flex-direction: row; 
	display: flex;
	cursor: pointer;
}

.MenuSortDown {
	margin-bottom: 2px;
}

.disabledComponentsMenu {
	opacity: 0.5;
	cursor: initial;
}

.App-header-roures {
	margin-top: 15px;
}

.App-header-roures>a {
	margin-left: 10px;
	margin-right: 10px;
	text-decoration: none;
	font-size: 15px;
}

.App-header-roures>a.regular {
	color: var(--white-color);
	text-decoration: none;
}

.App-header-roures>a.regular:hover {
	color: var(--primary-color);
}

.App-header-roures>a.selected {
	font-weight: 600;
	color: var(--primary-color);
	border-bottom: 4px var(--primary-color) solid;
	padding-bottom: 15px;
	text-decoration: none;
}

.AdminHeader{
	font-size: 14px;
	vertical-align: 2px;
	font-weight: 400;
	text-decoration: none;
	color: var(--primary-color);
}

.logo-link:hover{
	text-decoration: none;
}