.UserHeader {
    padding-top: 40px;
    padding-bottom: 15px;
    padding-left: 70px;
}

.UserHeader .UserName {
    font-weight: bold;
    margin-bottom: 15px;
}

span.warning {
    color: orange;
    font-weight: 600;
    font-size: 16px;
}

span.highlighted-text {
    color: var(--primary-color);
    font-weight: 600;
    font-size: 16px;
}

.MuiButton-outlined.QuanButton {
    border-width: 2px;
}

.MuiButton-outlined>.MuiButton-label {
    color: var(--highlighted-text-color);
}

.MuiButton-contained.QuanButton {
    background-color: var(--highlighted-text-color);
}

.MuiButton-contained.QuanButton>.MuiButton-label {
    color: white;
}

.QuanButton.MuiButton-root {
    width: 110px;
    height: 35px;
    margin: 5px;
}

.QuanButton.SmallButton.MuiButton-root {
    width: 90px;
    height: 30px;
}

.QuanButton.SmallButton.MuiButton-root.LongButton {
    width: fit-content;
}

.QuanButton.MuiButton-root>.MuiButton-label {
    text-transform: none;
    font-size: 12px;
}

.InfoColumnHeader.MuiGrid-root:first-of-type {
    margin-left: 65px;
}

.InfoColumnHeader.MuiGrid-root .MuiTypography-root {
    font-weight: bold;
    font-size: 18px;
}

.QuanLabel {
    font-size: 14px;
    color: #797979;
    margin-left: 70px;
}

.MuiGrid-root.QuanLabel+.MuiGrid-grid-xs-8 {
    font-size: 14px;
    font-weight: 700;
    padding-left: 0;
    display: inline-block;
}

.GridUserCol.MuiGrid-root {
    padding-left: 50px;
    border-right: 1px solid #dddddd;
}

.GridUserCol.MuiGrid-root:last-of-type {
    border-right: 0;
}

.QuanInstrument>.MuiListItemText-primary {
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 7px;
}

.QuanInstrument>.MuiListItemText-secondary {
    font-size: 12px;
}

.LabelInactive {
    font-size: 10px;
    color: #797979;
    margin-left: 7px;
}

.LabelActive {
    font-size: 10px;
    color: var(--primary-color);
    margin-left: 7px;
}

.UserEditLegend.MuiFormLabel-root, .UserEditLegend.MuiFormControlLabel-root .MuiFormControlLabel-label {
    font-size: 14px;
    color: black;
    font-weight: 600;
}

.UserEdit .MuiInputBase-root {
    font-size: 14px;
    position: relative;
    height: 40px;
    width: 360px;
}

.UserEdit>.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: var(--primary-color);
}

.UserEditLegend .MuiCheckbox-root {
    color: var(--primary-color);
}

.UserEditLegend .MuiCheckbox-root.Mui-checked {
    color: var(--primary-color);
}

.MuiListItemText-root {
    margin: 0;
}

.UsersTable {
    min-width: 1300px;
    overflow-x: scroll;
}

.UsersTableHeader .MuiTableCell-root.MuiTableCell-head {
    font-weight: 700;
}

.MuiTableContainer-root.UsersContainerHeader {
    margin: 20px;
    /* overflow-x: none!important; */
}

.MuiTableContainer-root.UsersContainer {
    margin: 20px auto;
    /* overflow-x: none!important; */
    /* overflow: auto;
    height: 560px;  */
}

.UsersTableBody .MuiTableCell-root.MuiTableCell-body {
    font-size: 0.9rem;
    table-layout: 'fixed';
}

.actionIcon {
    color: var(--highlighted-text-color);
    /* width: 40px; */
    /* min-width: 40px; */
}

span.warning.small, span.highlighted-text.small {
    font-size: 0.9rem;
    white-space: nowrap;
}

.UsersTableBody .MuiTableCell-root.MuiTableCell-body .QuanButton.MuiButton-root {
    margin: 0;
    margin-left: 3px;
    width: 50px;
    min-width: 45px;
    font-size: 0.7rem;
    font-weight: 700;
    padding-left: 0;
    padding-right: 0;
}

.UsersTableBody .MuiTableCell-root.MuiTableCell-body .QuanButton.MuiButton-root .MuiButton-label{
    font-size: 0.7rem;
    font-weight: 700;
}

.UserListHeader{
    margin-top: 20px!important; 
}

.Clickable{
    cursor: pointer;
}

.IOSSwitchColumn{
    width:135px;
}

.UsersFilter.MuiTextField-root{
    margin-left:40px;
}

.UsersFilter label{
    font-size: 12px;
}

.UsersFilter .MuiInput-underline{
    height: 40px;
    font-size: 12px;
    border: 0px white;
}

.UsersFilter .MuiInput-underline::before{
    border: 0px white;
}

.UsersFilter .MuiInput-underline::after{
    border: 0px white;
}

.UsersFilterContainer {
    margin-left: 9%;
}