.showProgressOverlay
{
  position: absolute;
  top:0px;
  bottom: 0px;
  right: 0px;
  left:0px; 
  z-index: 100;
  height: inherit;
}

.hideProgress
{
  display:none;  
}

.centerSpinner 
{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -45%);
  color: var(--primary-color);
}
.centerSpinner .MuiCircularProgress-svg
{
  color: var(--primary-color);
}

.uploaderContainer
{
  position: relative;
  height: 100%;
}